$(document).ready(function() {
    $('#email-subject-help, #email-greeting-help').popover({
        html: true,
        placement: 'left',
        trigger: 'hover',
        content: '<div><p>You can enter custom values as part of your email subject line and/or greeting line to personalize the message to the recipient.</p><p>Four custom values are available:<br /><strong>First name</strong> = [first_name]<br /><strong>Last name</strong> = [last_name]</br><strong>Date</strong> = [date]</p><p>For example:<br>Hello <strong>[first_name] [last_name]</strong>, here is your newsletter for <strong>[date]</strong>.</p><p><em>would be displayed as</em>:<br>Hello <strong>John Doe</strong>, here is your newsletter for <strong>January 1, 2013.</strong></p><p><strong>NOTE:</strong> You must enter the custom values EXACTLY as displayed or emails will not send correctly.</div>'
    });
    $('#dynamic-subject-help').popover({
        html: true,
        placement: 'left',
        trigger: 'hover',
        content: '<div><p>The title of the first article will be used to override the newsletter subject line.</p></div>'
    });
    $('#email-from-help').popover({
        html: true,
        placement: 'top',
        trigger: 'hover',
        content: '<div><p>The "Email From" link is the text that will show in an email application (i.e., Outlook) on the "From" line.</p></div>'
    });
    $('#email-firm-help').popover({
        html: true,
        placement: 'top',
        trigger: 'hover',
        content: '<div><p>The "Email Firm" link is the text that will show in your newsletter wherever a link to your email appears.</p></div>'
    });
    $('#custom-header-help').popover({
        html: true,
        placement: 'top',
        trigger: 'hover',
        content: '<div><p>If you plan to add custom content to your newsletter, you can add a heading for that content with this form field.</p></div>'
    });
    $('#facebook-help').popover({
        html: true,
        placement: 'top',
        trigger: 'hover',
        content: '<div><p>You only need to enter the last part of your Facebook URL into the form field.</p><p>For example, the Facebook URL for HomeActions is:<br />https://facebook.com/HomeActions</p><p>In this scenario, you would only enter "HomeActions" into the form field.</p></div>'
    });
    $('#twitter-help').popover({
        html: true,
        placement: 'top',
        trigger: 'hover',
        content: '<div><p>You only need to enter the last part of your Twitter URL into the form field.</p><p>For example, the Twitter URL for HomeActions is:<br />https://twitter.com/HomeActions</p><p>In this scenario, you would only enter "HomeActions" into the form field.</p></div>'
    });
    return $('#linkedin-help').popover({
        html: true,
        placement: 'top',
        trigger: 'hover',
        content: '<div><p>You only need to enter the last part of your LinkedIn URL into the form field.</p><p>For example, the LinkedIn URL for HomeActions is:<br />https://linkedin.com/company/1542710</p><p>In this scenario, you would only enter "1542710" into the form field.</p></div>'
    });
});
