// require("@rails/ujs").start(); // disabled atm because of smart_listing
require("@rails/activestorage").start();
require("channels");
require("chart.js");

window.toastr = require("toastr");

// actiontext stuff
require("trix")
require("@rails/actiontext")
// import "trix";
// import "@rails/actiontext";

// site-wide scripts
import "jquery/src/jquery";
import "jquery-ujs/src/rails"; // this will be removed when smart listing goes away
import "jquery-chained/jquery.chained";
import "bootstrap/dist/js/bootstrap";
import "moment";
import "jasny-bootstrap/dist/js/jasny-bootstrap";

// import "corejs-typeahead/dist/typeahead.jquery.min";
import "spectrum-colorpicker/spectrum";
import "chosen-js/chosen.jquery";
import "cocoon-js";
import "../src/ckeditor_base";
import "ckeditor4/ckeditor";
import "../src/ckeditor_plugins";
import "lightbox2/dist/js/lightbox";
import "@client-side-validations/client-side-validations";
import "@client-side-validations/simple-form";
import "peity/jquery.peity";
import "controllers";

// custom JS
import "../src/bootstrap-datetimepicker";
import "../src/smart_listing.coffee.erb";
import "../src/material/material";
import "../src/material/ripples";
import "../src/material/sidebar_nav";
import "../src/base";
import "../src/helpers";
import "../src/pillbox";
import "../src/calendar";
import "../src/toastr_alert";
import "../src/pages";

// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("corejs-typeahead/dist/typeahead.jquery.min");

window.moment = moment;
window.Bloodhound = require("corejs-typeahead/dist/bloodhound.min");

document.addEventListener("autocomplete.loadend", function() {
  // this is some hard-coded crap because, with the 6.x line, it's no longer going to be needed/used/exist
  let loaderElem = document.getElementById('loader')
  loaderElem.classList.remove('show')
  loaderElem.classList.add('hidden')
});
